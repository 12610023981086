<template>
    <div>

        <div style="background-color: #ffffff;text-align: left;padding: 10px 10px;border-radius: 5px;">
            <div style="margin: 0 auto;">
                <div>
                    <div class="pageCenter text-left m-b-20">
                        <div class="df-jc-s-b m-b-20">
                            <el-button-group>
                                <el-button size="mini" v-for="(v,index) of askAuditStatus" :key="index" @click="setCondition('askAuditStatus',v.id)" :type="QueryFurnitureLibrary.askAuditStatus==v.id?'primary':''">{{v.text}}</el-button>
                            </el-button-group>
                            <el-button-group>
                                <el-button size="mini" v-for="(v,index) of askFinishStatus" :key="index" @click="setCondition('askFinishStatus',v.id)" :type="QueryFurnitureLibrary.askFinishStatus==v.id?'primary':''">{{v.text}}</el-button>
                            </el-button-group>
                            <div>
                                <el-input size="mini" placeholder="请输入搜索标题" style="width: 350px;" v-model="QueryFurnitureLibrary.askTitle" class="input-with-select">
                                    <el-button slot="append" @click="search" icon="el-icon-search"></el-button>
                                </el-input>
                            </div> 
                        </div>
                        <div class="" v-if="commentSpuList.content">
                            <el-table :data="commentSpuList.content" style="width: 100%">
                                <el-table-column align="center" prop="name" label="求购产品信息" width="400">
                                    <template slot-scope="scope">
                                        <div class="df">
                                            <div class="vimgs">
                                                <div v-if="getFormType(scope.row.buyAskForms, 7)">
                                                    <span v-for="(v,i) of scope.row.buyAskForms" :key="i" v-if="v.buyAskCategoryForm && v.buyAskCategoryForm.formType == 7">
                                                        <span v-for="(vv,ii) of v.value" v-if="ii<1" :key="ii">
                                                            <img :src="$oucy.ossUrl+ vv.value" class="vimg" v-if="vv.type=='image'">
                                                            <video :src="$oucy.ossUrl+ vv.value" class="vimg" v-if="vv.type=='video'" controls></video>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div v-else class="text-center df-ai-c df-jc-c">
                                                  <div class="vimg" style="width: 100px;height: 100px;background: #f1f1f1;line-height: 100px;">
                                                      <div>无图</div>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="text-left p-l-10" style="flex:1">
                                                <div class="f14 bold c-1">{{scope.row.askTitle}}</div>
                                                <div class="product_title" :class="getClass(scope.row)">
                                                    {{scope.row.ltBuyAskCategoryDto.categoryName}}
                                                </div>
                                                <div class="c-2 line-2"> <span class="c-2 f12" v-for="v of scope.row.buyAskForms" :key="v.id" v-if="v.buyAskCategoryForm.formType==2">{{v.buyAskCategoryForm.formTitle}}:{{v.value}}</span></div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column align="left" prop="address" label="联系人信息">
                                    <template slot-scope="scope">
                                        <div class="bold"><span class="m-r-10">{{scope.row.askContactName}}</span> {{scope.row.askContactPhone}}</div>
                                        <div>{{scope.row.askContactPositionName}}</div>
                                    </template>
                                </el-table-column>
                                <!-- 审核状态：0未审核 1审核中 2审核成功 3审核驳回 -->
                                <el-table-column align="left" prop="address" label="求购状态">
                                    <div slot-scope="scope" class="f12">
                                        <div v-if="scope.row.askAuditStatus==0">未审核</div>
                                        <div class="c_20" v-else-if="scope.row.askAuditStatus==1">审核中</div>
                                        <div v-else-if="scope.row.askAuditStatus==2">审核成功</div>
                                        <div v-else-if="scope.row.askAuditStatus==3">审核驳回</div>

                                        <div>{{scope.row.askFinishStatus?'已完成':'未完成'}}</div>
                                        <!-- <div>{{scope.row.askShelvesStatus?'上架中':'下架中'}}</div> -->
                                    </div>
                                </el-table-column>
                                <el-table-column align="left" prop="askCreatetime" label="发布时间" width="200">
                                    <div slot-scope="scope" class="f12">
                                        <div>创建：{{scope.row.askCreatetime}}</div>
                                        <div v-if="scope.row.askUpdatetime">更新：{{scope.row.askUpdatetime}}</div>
                                    </div>
                                </el-table-column>
                                <el-table-column prop="address" label="操作" width="180">
                                    <template slot-scope="scope">
                                        <el-button class="mbtn" v-if="scope.row.askFinishStatus && 0" @click="del(scope.row)" size="mini">删除</el-button>
                                        <el-button class="mbtn" type="primary" v-if="!scope.row.askFinishStatus && scope.row.askAuditStatus==2" @click="refreshBuyAsk(scope.row)" size="mini">刷新</el-button>
                                        <el-button class="mbtn" type="primary" v-if="!scope.row.askFinishStatus && scope.row.askAuditStatus==2" @click="scope.row.askTopping?buyAskCancel(scope.row):buyAskTopping(scope.row)" size="mini">{{scope.row.askTopping?'取消置顶':'置顶求购'}}</el-button>
                                        <!-- <el-button class="mbtn" @click="strandUpAndDown(scope.row)" size="mini">{{scope.row.askShelvesStatus?'下架':'上架'}}</el-button> -->
                                        <el-button class="mbtn" type="primary" v-if="scope.row.askAuditStatus==2"  @click="finishBuyAsk(scope.row)" size="mini">{{scope.row.askFinishStatus?'取消完成':'完成'}}</el-button>
                                        <el-button class="mbtn" type="primary" @click="$oucy.go('/ReleaseCenterAskBuySave?id='+scope.row.id)" size="mini">编辑</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div v-else class="p-50 c-2 text-center">没有求购</div>
                    </div>
                </div>
                <div class="pagination" style="background-color: #ffffff;padding: 20px 0;">
                    <el-pagination class="m-t-10" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="commentSpuList.size" :total="commentSpuList.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                    </el-pagination>
                </div>
                <!-- <div ref="myLoginQrCodeUrl"></div> -->
            </div>
        </div>
        <refreshTopAskBuy ref="refreshTopAskBuy" @submit="setTOPRefresh"></refreshTopAskBuy>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { schemeOrder, buyAsk,buyAskCategory } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"
import QRCode from 'qrcodejs2'
export default {
    name: "Enter",
    data() {
        return {
            buyAskCategory: [],
            levelList: [],
            //审核状态：0未审核 1审核中 2审核成功 3审核驳回
            askAuditStatus: [
                { text: '全部', id: null },
                { text: '未审核', id: 0 },
                { text: '审核中', id: 1 },
                { text: '审核成功', id: 2 },
                { text: '审核驳回', id: 3 },
            ],
            askFinishStatus: [
                { text: '全部', id: null },
                { text: '未完成', id: false },
                { text: '已完成', id: true },
            ],
            askShelvesStatus: [
                { text: '全部', id: null },
                { text: '未上架', id: false },
                { text: '已上架', id: true },
            ],
            // 请求对象
            QueryFurnitureLibrary: {
                askAuditStatus: null,
                askFinishStatus: null,
                askShelvesStatus: null,
                limit: 10,
                start: 0,
                orderSchemeSpuSortEnum: 0, //0 最新排序 1默认排序
                spuId: null
            },
            commentSpuList: {},
        }
    },
    computed: {

    },
    watch: {
/*        $route: {
            handler(to, from) {
                this.getBreadcrumb()
            },
            deep: true,
            immediate: true
        }*/
    },
    mounted() {
        this.getAllBuyAskCategory()
        this.queryUserBuyAsk()
        this.levelList = [
            { path: '/', meta: { title: '首页' } },
            { path: '/acc/userinfo', meta: { title: '个人中心' } },
            {  meta: { title: '求购管理' } },
        ]
    },
    methods: {
        // 面包屑
        getBreadcrumb() {
            //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
            let matched = this.$route.matched.filter(item => item.name)
            const first = matched[0];
            if (first && first.name !== '首页') {
                matched = [{ path: '/', meta: { title: '首页' } }].concat(matched)
            }
            this.levelList = matched
        },
        // 请求列表
        queryUserBuyAsk() {
            buyAsk.queryUserBuyAsk(this.QueryFurnitureLibrary).then(res => {
                if (res.content) {

                    for (let v of res.content) {
                        if (v.buyAskForms) {
                            for (let vv of v.buyAskForms) {
                                if (vv.buyAskCategoryForm.formType == 7 && vv.value && typeof vv.value == 'string') {
                                    vv.value = JSON.parse(vv.value)
                                }
                            }
                        }
                    }

                }
                this.commentSpuList = res
            })
        },
        search() {
            this.QueryFurnitureLibrary.start=0
            this.queryUserBuyAsk()
        },

        // 切换页数
        sizeChange(pageSize) {
            this.QueryFurnitureLibrary.limit = pageSize;
            this.queryUserBuyAsk();
        },
        // 切换页码
        currentChange(current) {
            this.QueryFurnitureLibrary.start = current - 1;
            this.queryUserBuyAsk();
        },
        // 切换
        setCondition(t,v) {
            this.QueryFurnitureLibrary[t] = v
            this.queryUserBuyAsk();
        },

        del(v,i) {
            this.$confirm('确认删除！', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('点了确认')
                this.deleteBuyAsk(v,i)
            }).catch(() => {
                console.log('点了取消')
            });
        },
        // 删除买家发布求购信息
        deleteBuyAsk(v,i){
            buyAsk.deleteBuyAsk({id:v.id}).then(res=>{
                this.$message('删除成功')
                this.commentSpuList.content.splice(i,1)
            })
        },
        // 刷新求购
        refreshBuyAsk(v,i){
            this.$refs.refreshTopAskBuy.open({v:v,type:'refresh'})
            return
            buyAsk.refreshBuyAsk({id:v.id}).then(res=>{
                v.askUpdatetime=new Date().format('yyyy-MM-dd hh:mm:ss')
                this.$message('刷新成功')
            })
        },
       // 求购置顶 /取消置顶
        buyAskTopping(v,i){
            this.$refs.refreshTopAskBuy.open({v:v,type:'top'})
            return
            buyAsk.buyAskTopping({id:v.id}).then(res=>{
                this.$message('设置成功')
                this.queryUserBuyAsk()

            })
        },
       //取消置顶
        buyAskCancel(v,i){
            this.$confirm('确认取消置顶！', '取消置顶', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('点了确认')
                buyAsk.buyAskCancel({id:v.id}).then(res=>{
                    this.$message('设置成功')
                    this.queryUserBuyAsk()
                })
            }).catch(() => {
                console.log('点了取消')
            });

        },
        setTOPRefresh(){
            this.QueryFurnitureLibrary.start=0
            this.queryUserBuyAsk()
        },
       // 上下架
        strandUpAndDown(v,i){
            buyAsk.strandUpAndDown({id:v.id}).then(res=>{
                this.$message('设置成功')
                v.askShelvesStatus=!v.askShelvesStatus
            })
        },
           // 完成/取消求购
        finishBuyAsk(v,i){
            buyAsk.finishBuyAsk({id:v.id}).then(res=>{
                this.$message('设置成功')
                v.askFinishStatus=!v.askFinishStatus
            })
        },
        getAllBuyAskCategory() {
            buyAskCategory.getAllBuyAskCategory().then(res => {
                this.buyAskCategory.push(...res)
                // this.QueryFurnitureLibrary.askCategoryId = res[0].id
                
            })
        },
        getClass(v){
            for (var i = 0; i < this.buyAskCategory.length; i++) {
                if(this.buyAskCategory[i].categoryName==v.ltBuyAskCategoryDto.categoryName){
                    return 'status_'+i
                }
            }            
        },
        getFormType(v, t) {
          let has = false;
          if (v) {
            for (let vv of v) {
              if (vv.buyAskCategoryForm && vv.buyAskCategoryForm.formType == t) {
                if(vv.value && vv.value.length){
                    has = true;
                }
                break;
              }
            }
          }
          return has;
        },
    }
}
</script>
<style scoped lang="less">
.titleBar {
    height: 100px;
    /*background-color: #0366C3;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.titleBar_left {
    /*background-color: #42b983;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleBar_left_txt {
    /*background-color: #409EFF;*/
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    font-size: 25px;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px #eeeeee solid;
    color: gray;
}

.titleBar_right {
    font-size: 25px;
    color: gray;
}

.titleBar_right:hover {
    cursor: pointer;
    color: #000000;
}

.breadcrumbBar {
    padding: 10px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.breadcrumbBar.enterHeadWidth {
    padding: 0 60px;
}

.el-menu-vertical-demo {
    text-align: left;
    min-height: 700px;
    border-radius: 4px;
}

.el-menu-item,
.el-submenu__title {
    font-size: 16px;
}

.el-menu-item span {
    font-size: 16px;
}

.dialogImg {
    width: 104px;
    height: 104px;
    margin-right: 10px;
    margin-top: 10px;
}

.btn {
    line-height: 1.2;
    display: inline-block;
    padding: 6px 36px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}

.logo {
    width: 27px;
    height: 27px;
}

.vimgs {
    overflow: hidden;
    width: 100px;
    height: 100px;
}

.vimg {
    width: 100px;
    height: 100px;
}

.c_20 {
    color: #2090FF;
}

.product_title {
    font-size: 12px;
    display: inline-block;
    border-radius: 2px;
    color: #fff;
    padding: 0px 6px;
    font-weight: 600;

    &.status_0,
    &.status_1,
    &.status_4,
    &.status_7,
    &.status_9 {
        background: #E78238;
    }

    &.status_2,
    &.status_5,
    &.status_8,
    &.status_10 {
        background: #49A7FF;
    }

    &.status_3,
    &.status_6,
    &.status_9,
    &.status_11 {
        background: #EB3C3C;
    }
}
.mbtn{
    margin-left: 10px;
    margin-top: 10px;
}
</style>